/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@2.2.4/dist/jquery.min.js
 * - /npm/angular@1.7.4/angular.min.js
 * - /gh/scbd/angular-flex@2.2.2/angular-flex.min.js
 * - /npm/angular-route@1.7.4/angular-route.min.js
 * - /npm/angular-cookies@1.7.4/angular-cookies.min.js
 * - /npm/angular-sanitize@1.7.4/angular-sanitize.min.js
 * - /npm/angular-animate@1.7.4/angular-animate.min.js
 * - /npm/angular-aria@1.7.4/angular-aria.min.js
 * - /npm/angular-cache@4.6.0/dist/angular-cache.min.js
 * - /npm/lodash@4.17.15/lodash.min.js
 * - /npm/requirejs@2.2.0/require.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
